(function ($) {
    $(document).ready(function () {
        $('.scroll').on('click', function (event) {
            event.preventDefault();
            var hash = event.target.hash;
            if (hash === '#top') {
                $('html, body').animate({
                    scrollTop: 0
                }, 500);
            } else {
                var windowWidth = $(window).width();
                var headerHeight = 70;
                if (windowWidth < 768) {
                    headerHeight = 53;
                }

                $('html, body').animate({
                    scrollTop: $(hash).offset().top - headerHeight
                }, 500);
            }
        });
    });
}(jQuery));