(function ($) {
    $(document).ready(function () {
        var el = {
            plus: 'glyphicon glyphicon-plus',
            minus: 'glyphicon glyphicon-minus'
        }

        function openPanel() {
            var hash = window.location.hash;
            var pathName = window.location.pathname;
            if (hash !== '' && pathName === '/faq.html') {
                var panel = hash.replace('heading', 'collapse');
                $(panel).addClass('in');
            } else {
                $('#collapse-1').addClass('in');
            }
        }

        function addIcons() {
            $('#accordion .panel-collapse').each(function () {
                if ($(this).is(':visible')) {
                    $(this).parent().find('.panel-title > a').append('<span class="' + el.minus + '" aria-hidden="true"></span>');
                } else {
                    $(this).parent().find('.panel-title > a').append('<span class="' + el.plus + '" aria-hidden="true"></span>');
                }
            });
        }

        function changeIcons($this) {
            if ($this.parents('.panel').find('.panel-collapse').is(':hidden')) {
                $('#accordion .panel-title > a > span').attr('class', el.plus);
                $this.find('span').attr('class', el.minus);
            } else {
                $this.find('span').attr('class', el.plus);
            }
        }

        openPanel();
        addIcons();

        $('#accordion .panel-title > a').on('click', function () {
            changeIcons($(this));
        });
    });
}(jQuery));