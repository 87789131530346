(function($) {
    $(document).ready(function() {
        function navbarShrink() {
            if ($(window).scrollTop() >= 300) {
                $('.navbar').addClass('navbar-shrink');
            } else {
                $('.navbar').removeClass('navbar-shrink');
            }
        }
        navbarShrink();
        $(window).on('scroll', function() {
            navbarShrink();
        });
    });
}(jQuery));